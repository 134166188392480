import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AdminHeaderTabComponent } from './components/admin-header-tab/admin-header-tab.component';
import { ApplianceSliderComponent } from './components/appliance-slider/appliance-slider.component';
import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { ChartComponent } from './components/chart/chart.component';
import { FileUploadDropzoneComponent } from './components/file-upload-dropzone/file-upload-dropzone.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { GeoRegioSelectComponent } from './components/geo-regio-select/geo-regio-select.component';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { InlineSelectComponent } from './components/inline-select/inline-select.component';
import { MenuSideNavComponent } from './components/menu-side-nav/menu-side-nav.component';
import { PaymentPopupComponent } from './components/payment-popup/payment-popup.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegionMapComponent } from './components/region-map/region-map.component';
import { ColumnActionsComponent } from './components/summary-table/components/column-actions/column-actions.component';
import { ColumnAmountComponent } from './components/summary-table/components/column-amount/column-amount.component';
import { ColumnCostInfoComponent } from './components/summary-table/components/column-cost-info/column-cost-info.component';
import { ColumnLabourCostComponent } from './components/summary-table/components/column-labour-cost/column-labour-cost.component';
import { ColumnLabourUnitPriceComponent } from './components/summary-table/components/column-labour-unit-price/column-labour-unit-price.component';
import { ColumnMaterialCostComponent } from './components/summary-table/components/column-material-cost/column-material-cost.component';
import { ColumnMaterialUnitPriceComponent } from './components/summary-table/components/column-material-unit-price/column-material-unit-price.component';
import { ColumnNameComponent } from './components/summary-table/components/column-name/column-name.component';
import { ColumnShadowedComponent } from './components/summary-table/components/column-shadowed/column-shadowed.component';
import { ColumnTotalCostComponent } from './components/summary-table/components/column-total-cost/column-total-cost.component';
import { SummaryTableComponent } from './components/summary-table/summary-table.component';
import { ToggleButtonsBarComponent } from './components/toggle-buttons-bar/toggle-buttons-bar.component';
import { NgxDropzoneImagePreviewOnPushFixerDirective } from './directives/ngx-dropzone-image-preview-on-push-fixer.directive';
import { TableFooterRowAnchorDirective } from './directives/table-footer-row-anchor.directive';
import { AppCurrencyPipe } from './pipes/currency.pipe';
import { ObjectTranslationPipe } from './pipes/object-translation.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { StringMessageTranslationPipe } from './pipes/string-message-translation.pipe';
import { TotalCostFormatting } from './pipes/totalcost-formatting.pipe';
import { TranslateProjectTypePipe } from './pipes/translate-project-type.pipe';
import { LetModule } from '@ngrx/component';
import { SupplierProductSelectorDialogComponent } from './components/building-structure-supplier-product/supplier-product-selector-dialog/supplier-product-selector-dialog.component';
import { SupplierProductInfoComponent } from './components/building-structure-supplier-product/supplier-product-info/supplier-product-info.component';
import { BuildingStructureSelectorComponent } from './components/building-structure-supplier-product/building-structure-selector/building-structure-selector.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SupplierProductEditorComponent } from './components/building-structure-supplier-product/supplier-product-editor/supplier-product-editor.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { SupplierProductEditorDialogComponent } from './components/building-structure-supplier-product/supplier-product-editor-dialog/supplier-product-editor-dialog.component';
import { RecommendedItemComponent } from './components/building-structure-supplier-product/recommended-item/recommended-item.component';
import {
    LucideAngularModule,
    LayoutPanelTop,
    TreeDeciduous,
    BrickWall,
    Container,
    Fence,
    Landmark
} from 'lucide-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { ProjectPriceRange } from './pipes/project-price-range.pipe';
import { UnitPricePipe } from './pipes/unit-price.pipe';

@NgModule({
    imports: [
        CommonModule,
        AdroitNgUtilsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSidenavModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        NgxDropzoneModule,
        MatTableModule,
        MatButtonModule,
        MatCardModule,
        MatRadioModule,
        MatTabsModule,
        MatExpansionModule,
        MatMenuModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatListModule,
        MatSliderModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        LetModule,
        LucideAngularModule.pick({
            LayoutPanelTop,
            TreeDeciduous,
            BrickWall,
            Container,
            Fence,
            Landmark
        })
    ],
    declarations: [
        MenuSideNavComponent,
        HeaderComponent,
        FileUploadDropzoneComponent,
        HeaderDropdownComponent,
        ButtonDropdownComponent,
        SummaryTableComponent,
        ChartComponent,
        RegionMapComponent,
        NgxDropzoneImagePreviewOnPushFixerDirective,
        FileViewerComponent,
        ToggleButtonsBarComponent,
        TableFooterRowAnchorDirective,
        TranslateProjectTypePipe,
        AppCurrencyPipe,
        StatusPipe,
        StringMessageTranslationPipe,
        ObjectTranslationPipe,
        UnitPricePipe,
        ProjectPriceRange,
        ColumnActionsComponent,
        ColumnCostInfoComponent,
        ColumnShadowedComponent,
        ColumnNameComponent,
        ColumnAmountComponent,
        ColumnMaterialUnitPriceComponent,
        ColumnMaterialCostComponent,
        ColumnLabourUnitPriceComponent,
        ColumnLabourCostComponent,
        ColumnTotalCostComponent,
        AdminHeaderTabComponent,
        TotalCostFormatting,
        ProgressBarComponent,
        InlineSelectComponent,
        PaymentPopupComponent,
        GeoRegioSelectComponent,
        ApplianceSliderComponent,
        BuildingStructureSelectorComponent,
        SupplierProductSelectorDialogComponent,
        SupplierProductInfoComponent,
        SupplierProductEditorComponent,
        ImageUploadComponent,
        SupplierProductEditorDialogComponent,
        RecommendedItemComponent,
        LanguageSwitchComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MenuSideNavComponent,
        TranslocoModule,
        MatSidenavModule,
        HeaderComponent,
        FileUploadDropzoneComponent,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatAutocompleteModule,
        HeaderDropdownComponent,
        ButtonDropdownComponent,
        NgxDropzoneModule,
        SummaryTableComponent,
        ChartComponent,
        RegionMapComponent,
        FileViewerComponent,
        ToggleButtonsBarComponent,
        TranslateProjectTypePipe,
        MatButtonModule,
        AppCurrencyPipe,
        StatusPipe,
        StringMessageTranslationPipe,
        ObjectTranslationPipe,
        UnitPricePipe,
        ProjectPriceRange,
        MatCheckboxModule,
        MatCardModule,
        MatRadioModule,
        AdroitNgUtilsModule,
        MatTabsModule,
        AdminHeaderTabComponent,
        MatTableModule,
        CommonModule,
        MatExpansionModule,
        MatMenuModule,
        MatChipsModule,
        TotalCostFormatting,
        ProgressBarComponent,
        InlineSelectComponent,
        MatSlideToggleModule,
        MatDividerModule,
        MatListModule,
        PaymentPopupComponent,
        GeoRegioSelectComponent,
        ApplianceSliderComponent,
        MatSliderModule,
        MatNativeDateModule,
        BuildingStructureSelectorComponent,
        SupplierProductSelectorDialogComponent,
        SupplierProductInfoComponent,
        SupplierProductEditorComponent,
        ImageUploadComponent,
        LanguageSwitchComponent,
        RecommendedItemComponent
    ],
    providers: [MatIconRegistry, DatePipe, DecimalPipe, TotalCostFormatting]
})
export class SharedModule {
    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer
    ) {
        const icons = [
            'map_pin',
            ['location_pin', 'location-pin'],
            'electricity',
            ['fire_protection', 'fireprotection'],
            ['mechanical_engineering', 'mechanicalengineering'],
            ['method_of_contruction', 'methodofcontruction'],
            ['where_the_property', 'whereTheProperty'],
            ['plot_type', 'plotType'],
            ['design_frame', 'designFrame'],
            ['landscape_architecture', 'landscapeArchitecture'],
            ['public_utility', 'publicUtility'],
            ['gross_level_area', 'grossLevelArea'],
            ['planning_year', 'planningYear'],
            ['contact_list', 'contact-list'],
            ['plot_size', 'plotSize'],
            ['design_year', 'designYear'],
            ['currency', 'currency'],
            ['city', 'city'],
            ['street', 'street'],
            ['lot_no', 'lotNo'],
            ['street_no', 'streetNo'],
            ['zip_code', 'zipCode'],
            ['type', 'type'],
            ['search', 'search'],
            ['compare_delete', 'deleteCompare'],
            ['close', 'close'],
            ['check_mark', 'checkMark'],
            ['projects', 'menuProjects'],
            ['map', 'menuMap'],
            ['sales', 'menuSales'],
            ['itemmanagement', 'menuItemmanagement'],
            ['users', 'menuUsers'],
            ['profile', 'menuProfile'],
            ['logout', 'menuLogout'],
            ['arrow', 'arrow'],
            ['menu', 'menu'],
            ['selected_language', 'selectedLanguage'],
            ['circle_close', 'circleClose'],
            ['circle_check_mark', 'circleCheckMark'],
            ['upload', 'upload'],
            ['keystone-logo-v2', 'keystone-logo-v2'],
            ['keystone-logo', 'keystone-logo'],
            ['keystone-loading', 'keystone-loading'],
            ['green-circle', 'greenCircle'],
            ['green-circle-checkmark', 'greenCircleCheckMark'],
            ['lot-of-money', 'lotOfMoney'],
            ['kid_star', 'kid_star'],
            ['planner', 'planner'],
            ['unique-blueprint', 'unique-blueprint'],
            ['unique-construction', 'unique-construction'],
            ['unique-destination', 'unique-destination'],
            ['unique-eco-house', 'unique-eco-house'],
            ['unique-home', 'unique-home'],
            ['unique-home-1', 'unique-home-1'],
            ['unique-home-3', 'unique-home-3'],
            ['unique-housing-area', 'unique-housing-area'],
            ['unique-interior-design', 'unique-interior-design'],
            ['unique-location', 'unique-location'],
            ['unique-maintenance', 'unique-maintenance'],
            ['unique-natural-gas', 'unique-natural-gas'],
            ['unique-natural-gas-1', 'unique-natural-gas-1'],
            ['unique-pipe', 'unique-pipe'],
            ['unique-power', 'unique-power'],
            ['unique-rain', 'unique-rain'],
            ['unique-smart-home', 'unique-smart-home'],
            ['unique-urban-planning', 'unique-urban-planning'],
            ['unique-valley', 'unique-valley'],
            ['unique-water', 'unique-water'],
            ['unique-area', 'unique-area'],
            ['unique-wooden-house', 'unique-wooden-house'],
            ['unique-air-flow', 'unique-air-flow'],
            ['unique-downhill-terrain', 'unique-downhill-terrain'],
            ['unique-fence', 'unique-fence'],
            ['unique-fence-star', 'unique-fence-star'],
            ['unique-flat-terrain', 'unique-flat-terrain'],
            ['unique-flat-terrain-2', 'unique-flat-terrain-2'],
            ['unique-marshland-area', 'unique-marshland-area'],
            ['unique-solar-panel', 'unique-solar-panel'],
            ['unique-toxic-area', 'unique-toxic-area'],
            ['unique-furniture', 'unique-furniture'],
            ['unique-mining', 'unique-mining'],
            ['unique-smart-home-2', 'unique-smart-home-2'],
            ['model-phase', 'model-phase'],
            ['no-model-phase', 'no-model-phase'],
            ['floor0-0', 'floor0-0'],
            ['floor0-1', 'floor0-1'],
            ['floor0-2', 'floor0-2'],
            ['floor0', 'floor0'],
            ['floor1', 'floor1'],
            ['floor2', 'floor2'],
            ['floor3', 'floor3'],
            ['green-email', 'green-email'],
            ['email', 'email'],
            ['renew-subscription', 'renew-subscription'],
            ['unique-offer', 'unique-offer'],
            // new icons
            'plan',
            'diamond',
            'diamond-color',
            'quote',
            'family-house',
            'holiday-resort',
            'other-building',
            'upload-file',
            'umbrella-beach-regular',
            'plan-current-color',
            'new-project',
            'icon-done',
            'icon-info',
            'icon-x',
            'icon-alert',
            'upload-cloud',
            'file',
            'google',
            'google-brands-solid',
            'facebook-f-brands-solid',
            'facebook',
            'google',
            'sliders-h-duotone',
            'sliders-h-square-solid',
            'sliders-h-square-duotone',
            'sliders-h-solid',
            'tools-light'
        ];

        for (const icon of icons) {
            this.matIconRegistry.addSvgIcon(
                Array.isArray(icon) ? icon[0] : icon,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `/assets/imgs/icons/${Array.isArray(icon) ? icon[1] : icon}.svg`
                )
            );
        }
    }
}
