import { createAction, props } from '@ngrx/store';
import { ApplianceItemArchiveEntity, ApplianceTypeEntity, WorkTypeEntity } from 'app/api';
import { ApplianceUpsertProps } from '../../models/api/applianceUpsert.model';

export const getEquipmentTypes = createAction(
    '[Equipment Types Tab] Get equipment types from backend',
    props<{ date: string | null }>()
);
export const getEquipmentTypesSuccess = createAction(
    '[Equipment Types Tab] Get equipment types from backend success',
    props<{ equipmentTypes: Array<ApplianceTypeEntity> }>()
);
export const getEquipmentTypesFailure = createAction(
    '[Equipment Types Tab] Get equipment types from backend failure',
    props<{ error: string | null }>()
);

export const getEquipmentTypeArchive = createAction(
    '[Equipment Types Tab] Get equipment type archives from backend',
    props<{ id: string }>()
);
export const getEquipmentTypeArchiveSuccess = createAction(
    '[Equipment Types Tab] Get equipment type archives from backend success',
    props<{ id: string; equipmentTypeArchives: ApplianceItemArchiveEntity[] }>()
);
export const getEquipmentTypeArchiveFailure = createAction(
    '[Equipment Types Tab] Get equipment type archives from backend failure',
    props<{ error: string | null }>()
);

export const editApplianceItem = createAction(
    '[Equipment Types Tab] Edit equipment types to backend',
    props<ApplianceUpsertProps>()
);
export const editApplianceItemSuccess = createAction(
    '[Equipment Types Tab] Edit equipment types to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const editApplianceItemFailure = createAction(
    '[Equipment Types Tab] Edit equipment types to backend failure',
    props<{ error: string | null }>()
);

export const saveApplianceItem = createAction(
    '[Equipment Types Tab] Save Appliance Item to backend',
    props<ApplianceUpsertProps>()
);
export const saveApplianceItemSuccess = createAction(
    '[Equipment Types Tab] Save Appliance Item to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const saveApplianceItemFailure = createAction(
    '[Equipment Types Tab] Save Appliance Item to backend failure',
    props<{ error: string | null }>()
);

export const deleteApplianceItem = createAction(
    '[Equipment Types Tab] Delete Appliance Item from backend',
    props<{ id: string }>()
);
export const deleteApplianceItemSuccess = createAction(
    '[Equipment Types Tab] Delete Appliance Item from backend success',
    props<{ id: string }>()
);
export const deleteApplianceItemFailure = createAction(
    '[Equipment Types Tab] Delete Appliance Item from backend failure',
    props<{ error: string | null }>()
);

export const saveApplianceType = createAction(
    '[Equipment Types Tab] Save appliance types to backend',
    props<{ typeId: string }>()
);
export const saveApplianceTypeSuccess = createAction(
    '[Equipment Types Tab] Save appliance types to backend success'
);
export const saveApplianceTypeFailure = createAction(
    '[Equipment Types Tab] Save appliance types to backend failure',
    props<{ error: string | null }>()
);

export const getApplianceTypesToSelect = createAction(
    '[Equipment Types Tab] Get Appliance Types To Select from backend'
);
export const getApplianceTypesToSelectSuccess = createAction(
    '[Equipment Types Tab] Get Appliance Types To Select from backend success',
    props<{ data: Array<WorkTypeEntity> }>()
);
export const getApplianceTypesToSelectFailure = createAction(
    '[Equipment Types Tab] Get Appliance Types To Select from backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearEquipmentTypesData = createAction(
    '[Equipment Types Tab] Clear equipment type data'
);
