import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    ApplianceItemArchiveEntity,
    ApplianceTypeEntity,
    GeneralApiService
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as WindEnergyActions from './wind-energy.actions';
import { getWindEnergyApplianceTypes } from './wind-energy.actions';

@Injectable()
export class WindEnergyEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    AdminGetWindEnergyApplianceTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.getWindEnergyApplianceTypes),
            switchMap(() => {
                return this.generalApiService
                    .getWindEnergyApplianceTypes(/* { body: { queryDate: date } } */)
                    .pipe(
                        map((typeData: ApplianceTypeEntity[]) => {
                            return WindEnergyActions.getWindEnergyApplianceTypesSuccess({
                                windEnergyApplianceTypes: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                WindEnergyActions.getWindEnergyApplianceTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetWindEnergyApplianceTypeArchive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.getWindEnergyApplianceTypeArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getRenewableApplianceItemHistory({ id }).pipe(
                    map((windEnergyApplianceTypeArchives: ApplianceItemArchiveEntity[]) => {
                        return WindEnergyActions.getWindEnergyApplianceTypeArchiveSuccess({
                            id,
                            windEnergyApplianceTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            WindEnergyActions.getWindEnergyApplianceTypeArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditWindEnergyApplianceItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.editWindEnergyApplianceItem),
            switchMap(({ id, ...body }) => {
                return this.adminApiService
                    .updateRenewableApplianceItem({
                        id,
                        body
                    })
                    .pipe(
                        map((newValueData) => {
                            return WindEnergyActions.editWindEnergyApplianceItemSuccess({
                                id,
                                name: newValueData.name,
                                value: newValueData.value
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                WindEnergyActions.editWindEnergyApplianceItemFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editditWindEnergyApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.editWindEnergyApplianceItemSuccess),
            switchMap((action) => of(getWindEnergyApplianceTypes({ date: null })))
        )
    );

    SaveWindEnergyApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.saveWindEnergyApplianceItem),
            switchMap(({ id, ...body }) => {
                return this.adminApiService
                    .createWindEnergyApplianceItem({
                        body: {
                            typeId: id,
                            ...body
                        }
                    })
                    .pipe(
                        map((applianceTypeData: ApplianceTypeEntity[]) =>
                            WindEnergyActions.getWindEnergyApplianceTypesSuccess({
                                windEnergyApplianceTypes: applianceTypeData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                WindEnergyActions.saveWindEnergyApplianceItemFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    DeleteWindEnergyApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.deleteWindEnergyApplianceItem),
            switchMap(({ id }) =>
                this.adminApiService.deleteRenewableApplianceItem({ id }).pipe(
                    map((newValueData) =>
                        WindEnergyActions.deleteWindEnergyapplianceItemSuccess({
                            id
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            WindEnergyActions.deleteWindEnergyApplianceItemFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deleteWindEnergyApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.deleteWindEnergyapplianceItemSuccess),
            switchMap((action) => of(getWindEnergyApplianceTypes({ date: null })))
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WindEnergyActions.logout),
            map(() => WindEnergyActions.clearWindEnergyTypesData())
        )
    );
}
