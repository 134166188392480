import { createAction, props } from '@ngrx/store';
import { ApplianceItemArchiveEntity, ApplianceTypeEntity } from 'app/api';
import { ApplianceUpsertProps } from '../../models/api/applianceUpsert.model';

/* Get Appliance */
export const getSolarCollectorApplianceTypes = createAction(
    '[Solar Collector Types Tab] Get solar collector types from backend',
    props<{ date: string | null }>()
);
export const getSolarCollectorApplianceTypesSuccess = createAction(
    '[Solar Collector Types Tab] Get solar collector types from backend success',
    props<{ solarCollectorApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const getSolarCollectorApplianceTypesFailure = createAction(
    '[Solar Collector Types Tab] Get solar collector types from backend failure',
    props<{ error: string | null }>()
);

/* Get Appliance Archives */
export const getSolarCollectorApplianceTypeArchive = createAction(
    '[Solar Collector Types Tab] Get solar collector type archives from backend',
    props<{ id: string }>()
);
export const getSolarCollectorApplianceTypeArchiveSuccess = createAction(
    '[Solar Collector Types Tab] Get solar collector type archives from backend success',
    props<{ id: string; solarCollectorApplianceTypeArchives: ApplianceItemArchiveEntity[] }>()
);
export const getSolarCollectorApplianceTypeArchiveFailure = createAction(
    '[Solar Collector Types Tab] Get solar collector type archives from backend failure',
    props<{ error: string | null }>()
);

/* Edit Appliance item */
export const editSolarCollectorApplianceItem = createAction(
    '[Solar Collector Types Tab] Edit solar collector type to backend',
    props<ApplianceUpsertProps>()
);
export const editSolarCollectorApplianceItemSuccess = createAction(
    '[Solar Collector Types Tab] Edit solar collector type to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const editSolarCollectorApplianceItemFailure = createAction(
    '[Solar Collector Types Tab] Edit solar collector type to backend failure',
    props<{ error: string | null }>()
);

/* Save Appliance item */
export const saveSolarCollectorApplianceItem = createAction(
    '[Solar Collector Types Tab] Save solar collector appliance Item to backend',
    props<ApplianceUpsertProps>()
);
export const saveSolarCollectorApplianceItemSuccess = createAction(
    '[Solar Collector Types Tab] Save solar collector appliance Item to backend success',
    props<{ solarCollectorApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const saveSolarCollectorApplianceItemFailure = createAction(
    '[Solar Collector Types Tab] Save solar collector appliance Item to backend failure',
    props<{ error: string | null }>()
);

/* Delete Appliance item */
export const deleteSolarCollectorApplianceItem = createAction(
    '[Solar Collector Types Tab] Delete solar collector appliance Item from backend',
    props<{ id: string }>()
);
export const deleteSolarCollectorapplianceItemSuccess = createAction(
    '[Solar Collector Types Tab] Delete solar collector appliance Item from backend success',
    props<{ id: string }>()
);
export const deleteSolarCollectorApplianceItemFailure = createAction(
    '[Solar Collector Types Tab] Delete solar collector appliance Item from backend failure',
    props<{ error: string | null }>()
);

/* Clear Appliance items */
export const logout = createAction('[Header] Logout');
export const clearSolarCollectorTypesData = createAction(
    '[Solar Collector Types Tab] Clear solar collector types data'
);
