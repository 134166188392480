import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    ApplianceItemArchiveEntity,
    ApplianceTypeEntity,
    GeneralApiService,
    WorkTypeEntity
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as EquipmentTypesActions from './equipment-types.actions';
import { getEquipmentTypes } from './equipment-types.actions';

@Injectable()
export class EquipmentTypesEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    AdminGetEquipmentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.getEquipmentTypes),
            switchMap(({ date }) => {
                return this.generalApiService
                    .getApplianceTypes(/* { body: { queryDate: date } } */)
                    .pipe(
                        map((typeData: ApplianceTypeEntity[]) => {
                            return EquipmentTypesActions.getEquipmentTypesSuccess({
                                equipmentTypes: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                EquipmentTypesActions.getEquipmentTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetEquipmentTypeArchies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.getEquipmentTypeArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getApplianceItemHistory({ id }).pipe(
                    map((equipmentTypeArchives: ApplianceItemArchiveEntity[]) => {
                        return EquipmentTypesActions.getEquipmentTypeArchiveSuccess({
                            id,
                            equipmentTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            EquipmentTypesActions.getEquipmentTypeArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditEquipmentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.editApplianceItem),
            switchMap(({ id, ...body }) => {
                return this.adminApiService
                    .updateApplianceItem({
                        id,
                        body
                    })
                    .pipe(
                        map((newValueData) => {
                            return EquipmentTypesActions.editApplianceItemSuccess({
                                id,
                                name: newValueData.name,
                                value: newValueData.value
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                EquipmentTypesActions.editApplianceItemFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.editApplianceItemSuccess),
            switchMap((action) => of(getEquipmentTypes({ date: null })))
        )
    );

    SaveEquipmentTypesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.saveApplianceItem),
            switchMap(({ id, ...body }) => {
                return this.adminApiService
                    .createApplianceItem({
                        body: {
                            typeId: id,
                            ...body
                        }
                    })
                    .pipe(
                        map((applianceTypeData: ApplianceTypeEntity[]) =>
                            EquipmentTypesActions.getEquipmentTypesSuccess({
                                equipmentTypes: applianceTypeData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                EquipmentTypesActions.saveApplianceItemFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    DeleteEquipmentTypesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.deleteApplianceItem),
            switchMap(({ id }) =>
                this.adminApiService.deleteApplianceItem({ id }).pipe(
                    map((newValueData) =>
                        EquipmentTypesActions.deleteApplianceItemSuccess({
                            id
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            EquipmentTypesActions.deleteApplianceItemFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deleteApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.deleteApplianceItemSuccess),
            switchMap((action) => of(getEquipmentTypes({ date: null })))
        )
    );

    SaveApplianceTypesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.saveApplianceType),
            switchMap(({ typeId }) =>
                this.adminApiService
                    .createApplianceType({
                        body: { typeId }
                    })
                    .pipe(
                        map((applianceTypeData: ApplianceTypeEntity[]) =>
                            EquipmentTypesActions.getEquipmentTypesSuccess({
                                equipmentTypes: applianceTypeData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                EquipmentTypesActions.saveApplianceTypeFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        )
    );

    getApplianceTypesToSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.getApplianceTypesToSelect),
            switchMap(() =>
                this.adminApiService.getWorkTypeWithoutApplianceType().pipe(
                    map((workTypeEntityData: Array<WorkTypeEntity>) =>
                        EquipmentTypesActions.getApplianceTypesToSelectSuccess({
                            data: workTypeEntityData
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            EquipmentTypesActions.getApplianceTypesToSelectFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EquipmentTypesActions.logout),
            map(() => EquipmentTypesActions.clearEquipmentTypesData())
        )
    );
}
